import PropTypes from 'prop-types';
import React from 'react';
import { BackgroundImage, Container, Inner, Intro, Logo } from './styles';

export default function Hero({ data: { intro, backgroundImage, logo } }) {
  return (
    <Container id="start">
      <BackgroundImage
        fluid={backgroundImage.fluid}
        alt={backgroundImage.alt}
        style={{ position: 'absolute' }}
      />
      <Inner>
        <h1>
          <Logo src={logo.src} alt="Tantogårdens Bangolf" aria-hidden="true" />
        </h1>
        <Intro>{intro}</Intro>
      </Inner>
    </Container>
  );
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};
