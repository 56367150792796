import PropTypes from 'prop-types';
import React from 'react';
import { Markdown, Title } from '../../styles/global';
import ContactForm from '../ContactForm';
import Map from '../Map';
import OpeningHours from '../OpeningHours';
import PricesAndMembership from '../PricesAndMembership';
import Results from '../Results';
import { ContentWrapper, Section } from './styles';

export default function Block({
  data: {
    title,
    description,
    map,
    slug,
    keyValueTableTitle,
    keyValueTable,
    secondKeyValueTableTitle,
    secondKeyValueTable,
    exceptionsToOpeningHoursTitle,
    exceptionsToOpeningHours,
    results,
    footnote,
  },
}) {
  let theme = 'blue';
  let BlockSpecificComponents = () => null;

  switch (slug) {
    case 'om-oss':
      theme = 'red';
      BlockSpecificComponents = () => <Map data={map} />;
      break;
    case 'oppettider':
      theme = 'gray';
      BlockSpecificComponents = () => (
        <OpeningHours
          data={{
            keyValueTableTitle,
            keyValueTable,
            exceptionsToOpeningHoursTitle,
            exceptionsToOpeningHours,
          }}
        />
      );
      break;
    case 'priser-medlemskap':
      BlockSpecificComponents = () => (
        <PricesAndMembership
          data={{
            keyValueTableTitle,
            keyValueTable,
            secondKeyValueTableTitle,
            secondKeyValueTable,
          }}
        />
      );
      break;
    case 'kontakta-oss':
      BlockSpecificComponents = () => <ContactForm />;
      break;
    case 'resultat':
      theme = 'gray';
      BlockSpecificComponents = () => <Results results={results} />;
      break;
    case 'bangolfskola':
      theme = 'red';
      break;
    default:
      break;
  }

  return (
    <Section id={slug} theme={theme}>
      <ContentWrapper>
        <Title>{title}</Title>
        <Markdown theme={theme}>{description}</Markdown>

        {/*Following components will only be visible when data is available.*/}
        <BlockSpecificComponents />

        {footnote ? <Markdown theme={theme}>{footnote}</Markdown> : null}
      </ContentWrapper>
    </Section>
  );
}

Block.propTypes = {
  data: PropTypes.object.isRequired,
};
