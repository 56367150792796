import styled from 'styled-components';
import { screenSize } from '../../styles/variables';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  flex-direction: column;

  @media (min-width: ${screenSize.mdMin}) {
    flex-direction: initial;
  }
`;

export const ExceptionsToOpeningHours = styled.div`
  width: 100%;
  margin-top: 1rem;

  @media (min-width: ${screenSize.mdMin}) {
    width: 50%;
    margin-top: 0;
  }
`;
