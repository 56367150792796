import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors, fontSize, fontWeight } from '../../styles/variables';

const requiredDot = `
  position: relative;
  display: inline-block;
  content: '';
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: ${colors.red};
`;

const inputStyles = `
  width: 100%;
  background-color: ${colors.white};
  font-size: ${fontSize.small};
  padding: 0.4rem;
  font-family: 'Source Sans Pro', sans-serif;
  border-width: 0;

  &::placeholder {
    color: ${colors.gray};
  }
`;

export const Form = styled.form`
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
  font-size: ${fontSize.small};

  ${props => props.isRequired && `
    &::after {
      ${requiredDot}
      margin-left: 0.4rem;
    }
  `}
`;

export const Error = styled.span`
  position: absolute;
  font-size: ${fontSize.smaller};
  font-weight: ${fontWeight.default};
  right: 0;
  bottom: -1rem;
`;

export const Required = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const BotField = styled.div`
  display: none;
`;

export const Input = styled.input`
  ${inputStyles}
`;

export const Textarea = styled.textarea`
  ${inputStyles}
  height: 5rem;
`;

export const FormField = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Button = styled.button`
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: ${fontWeight.default};
  font-size: ${fontSize.small};
  background-color: transparent;
  border: 0.1rem solid ${colors.white};
  color: ${colors.white};
  padding: 0.4rem 0.5rem;

  &:hover {
    background-color: ${transparentize(0.8, colors.white)};
  }
`;

export const RequiredFieldExplanation = styled.p`
  display: flex;
  align-items: center;
  margin: 0;

  &::before {
    ${requiredDot}
    margin-right: 0.4rem;
  }
`;
