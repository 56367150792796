import PropTypes from 'prop-types';
import React from 'react';
import KeyValueTable from '../KeyValueTable';
import { Wrapper } from './styles';

export default function PricesAndMembership({
  data: {
    keyValueTable,
    keyValueTableTitle,
    secondKeyValueTable,
    secondKeyValueTableTitle,
  },
}) {
  return (
    <Wrapper>
      <KeyValueTable data={keyValueTable} title={keyValueTableTitle} />
      <KeyValueTable
        data={secondKeyValueTable}
        title={secondKeyValueTableTitle}
      />
    </Wrapper>
  );
}

PricesAndMembership.propTypes = {
  data: PropTypes.object.isRequired,
};
