import styled from 'styled-components';
import { colors, screenSize } from '../../styles/variables';

const THEMES = {
  gray: `
    background-color: ${colors.grayLight};
    color: ${colors.blueDark};
  `,
  red: `
    background-color: ${colors.red};
    color: ${colors.white};
  `,
  blue: `
    background-color: ${colors.blueDark};
    color: ${colors.white};
  `,
};

export const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  box-sizing: border-box;
  ${(props) => THEMES[props.theme]};

  &:nth-child(odd) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      left: 0;
      height: 50%;
      background: inherit;
      -webkit-backface-visibility: hidden;
    }

    &::before {
      top: 0;
      transform: skewY(-3deg);
      transform-origin: 0;
    }

    &::after {
      bottom: 0;
      transform: skewY(3deg);
      transform-origin: 0 100%;
      z-index: 1;
    }
  }

  &:nth-child(even) {
    padding: 4rem 0;

    @media (min-width: ${screenSize.mdMin}) {
      padding: 8rem 0;
    }
  }

  &:last-of-type {
    &::after {
      content: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2;
`;
