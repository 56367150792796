import PropTypes from 'prop-types';
import React from 'react';
import { Subtitle } from '../../styles/global';
import { Key, Table, Value, Wrapper } from './styles';

export default function KeyValueTable({ data, title }) {
  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      <Subtitle>{title}</Subtitle>
      <Table>
        <tbody>
          {data.map(item => (
            <tr key={title + item.key}>
              {item.key ? (
                <Key colSpan={item.value ? '1' : '2'}>{item.key}</Key>
              ) : null}
              {item.value ? (
                <Value colSpan={item.key ? '1' : '2'}>{item.value}</Value>
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
}

KeyValueTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};
