const REQUIRED_ERROR = 'Det här fältet är obligatoriskt.';

export default [
  {
    name: 'Namn',
    id: 'namn',
    type: 'text',
    placeholder: 'Ditt fullständiga namn',
    validation: {
      required: REQUIRED_ERROR,
    },
  },
  {
    name: 'Epostadress',
    id: 'epostadress',
    type: 'email',
    placeholder: 'ditt@mail.se',
    validation: {
      required: REQUIRED_ERROR,
      regex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
      error: 'Vänligen fyll i en giltig epostadress.',
    },
  },
  {
    name: 'Telefonnummer',
    id: 'telefonnummer',
    type: 'tel',
    placeholder: '070-1234567',
    validation: {
      regex: /^(?:\+\d{1,3}|00\d{1,3}|0)(?:\s*-*\s*\d){6,10}$/,
      error: 'Vänligen fyll i ett giltigt telefonnummer.',
    },
  },
  {
    name: 'Din fråga',
    id: 'text',
    type: 'textarea',
    placeholder: 'Skriv din fråga här...',
    validation: {
      required: REQUIRED_ERROR,
    },
  },
];
