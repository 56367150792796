import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import FORM_ELEMENTS from './form-elements';
import {
  BotField,
  Button,
  Error,
  Footer,
  Form,
  FormField,
  Input,
  Label,
  Required,
  RequiredFieldExplanation,
  Textarea
} from './styles';

export default function ContactForm() {
  const [values, setValues] = useState(
    FORM_ELEMENTS.reduce((acc, current, i) => {
      acc[current.id] = '';
      return acc;
    }, {})
  );
  const [errors, setErrors] = useState({});

  function encodeForm(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  function isItemValid(value, formElement) {
    if (!value || !formElement.validation.regex) {
      return true;
    }

    return formElement.validation.regex.test(value);
  }

  function isFormValid() {
    return !Object.values(errors).some(error => error);
  }

  function validateForm() {
    FORM_ELEMENTS.forEach(formElement => {
      validateItem(null, formElement.id);
    });
  }

  function validateItem(event, id) {
    id = id || event.target.id;
    const formElement = FORM_ELEMENTS.find(elem => elem.id === id);
    const value = values[formElement.id];
    const required = formElement.validation.required;

    if (required && !value) {
      setErrors({
        ...errors,
        [formElement.id]: required,
      });
    } else if (!isItemValid(value, formElement)) {
      setErrors({
        ...errors,
        [formElement.id]: formElement.validation.error,
      });
    } else {
      setErrors({
        ...errors,
        [formElement.id]: null,
      });
    }
  }

  function submitHandler(event) {
    event.preventDefault();
    validateForm();

    if (isFormValid()) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encodeForm({
          'form-name': 'contact',
          ...values,
        }),
      })
        .then(() => navigate('/tack'))
        .catch(error => alert(error));
    } else {
      console.error('something went wrong');
    }
  }

  function changeHandler(event) {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  return (
    <Form onSubmit={submitHandler}>
      <BotField>
        <Label htmlFor="bot-field">
          Fyll inte i den här när du är en människa
        </Label>
        <input
          type="text"
          name="bot-field"
          id="bot-field"
          onChange={changeHandler}
        />
      </BotField>
      {FORM_ELEMENTS.map(formElement => {
        const { type, name, id, validation, placeholder } = formElement;
        const error = errors[id];
        const errorAriaDescribedBy = error ? `${id}_error` : null;
        const requiredAriaDescribedby =
          validation.required && !error ? `${id}_required` : null;

        return (
          <FormField key={id}>
            <Label htmlFor={id} isRequired={validation.required}>
              {name}
            </Label>
            {type === 'textarea' ? (
              <Textarea
                id={id}
                name={id}
                required={validation.required}
                placeholder={placeholder}
                onChange={changeHandler}
                onBlur={validateItem}
                aria-describedby={
                  errorAriaDescribedBy || requiredAriaDescribedby
                }
              />
            ) : (
              <Input
                type={type}
                name={id}
                id={id}
                placeholder={placeholder}
                required={validation.required}
                onChange={changeHandler}
                onBlur={validateItem}
                aria-describedby={
                  errorAriaDescribedBy || requiredAriaDescribedby
                }
              />
            )}
            {validation.required ? (
              <Required id={`${id}_required`}>{validation.required}</Required>
            ) : null}
            {error ? (
              <Error role="alert" id={`${id}_error`}>
                {error}
              </Error>
            ) : null}
          </FormField>
        );
      })}
      <Footer>
        <Button type="submit" data-gtm-click="contact form submit">
          Skicka
        </Button>
        <RequiredFieldExplanation aria-hidden="true">Obligatoriska fält</RequiredFieldExplanation>
      </Footer>
    </Form>
  );
}
