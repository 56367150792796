import React from 'react';
import Block from '../components/Block';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import ImportantMessage from '../components/ImportantMessage';
import Layout from '../components/Layout';
import Navigation from '../components/Navigation';

function getVisibleImportantMessage(importantMessages) {
  const now = new Date();
  const allVisibleImportantMessages = importantMessages.filter(({ node }) => {
    const startDate = new Date(node.startDate);
    const endDate = node.endDate && new Date(node.endDate);

    return now > startDate && (!endDate || now < endDate);
  });

  return allVisibleImportantMessages.length
    ? {
        id: allVisibleImportantMessages[0].node.id,
        title: allVisibleImportantMessages[0].node.title,
        message: allVisibleImportantMessages[0].node.message.message,
      }
    : null;
}

export default function Index({
  pageContext: {
    metadata,
    navigation,
    hero,
    blocks,
    footer,
    importantMessages,
  },
}) {
  const importantMessage = getVisibleImportantMessage(importantMessages);

  return (
    <Layout metadata={metadata}>
      {importantMessage && <ImportantMessage data={importantMessage} />}
      <Navigation data={navigation.data} logo={navigation.logo} />
      <Hero data={hero} />
      {blocks.map((block) => (
        <Block key={block.id} data={block} />
      ))}
      <Footer data={footer} />
    </Layout>
  );
}
