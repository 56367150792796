import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { toggleImportantMessage } from '../../state/actions';
import { Context } from '../../state/store';
import { Markdown } from '../../styles/global';
import {
  ButtonBar,
  ButtonWrapper,
  CloseButton,
  Content,
  ContentsModal,
  ExclamationMark,
} from './styles';

export default function ImportantMessage({ data: { title, message } }) {
  const [state, dispatch] = useContext(Context);
  function toggleModal() {
    dispatch(toggleImportantMessage());
  }

  return (
    <>
      {!state.importantMessageIsOpen && (
        <ButtonWrapper>
          <ButtonBar type="button" onClick={toggleModal}>
            <ExclamationMark>!</ExclamationMark>
            {title}
          </ButtonBar>
        </ButtonWrapper>
      )}
      <ContentsModal
        isOpen={state.importantMessageIsOpen}
        onRequestClose={toggleModal}
      >
        <Content>
          <Markdown theme="red">{message}</Markdown>
        </Content>
        <CloseButton onClick={toggleModal}>✕</CloseButton>
      </ContentsModal>
    </>
  );
}

ImportantMessage.propTypes = {
  data: PropTypes.object.isRequired,
};
