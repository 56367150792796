import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  screenSize,
} from '../../styles/variables';

ReactModal.setAppElement('#___gatsby');
const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      aria-label="Viktigt meddelande"
      {...props}
    />
  );
};

export const ButtonWrapper = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: 3;

  @media (min-width: ${screenSize.mdMin}) {
    left: auto;
  }
`;

export const ButtonBar = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: ${colors.white};
  color: ${colors.red};
  font-size: ${fontSize.default};
  font-weight: ${fontWeight.medium};
  border: 3px solid ${colors.red};
  padding: 1rem;
`;

export const ExclamationMark = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.red};
  color: ${colors.white};
  font-weight: ${fontWeight.heaviest};
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.3rem;
`;

export const ContentsModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${colors.red};
    z-index: 3;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    color: ${colors.white};

    &:focus {
      outline: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 700px;
  margin: auto;
`;

export const CloseButton = styled.button`
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: ${fontWeight.default};
  font-size: ${fontSize.large};
  background-color: transparent;
  color: ${colors.red};
  border-width: 0;
  padding: 0;
  background-color: ${colors.white};
`;
