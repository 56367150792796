import L from 'leaflet';
import PropTypes from 'prop-types';
import React from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import tbgkLogo from '../../images/tantogardens-bangolf-logo.svg';
import { Container } from './styles';

export default function Map({ data }) {
  if (!data) {
    return null;
  }
  const markerPosition = [data.lat, data.lng];

  return (
    <Container>
      {typeof window !== 'undefined' && (
        <MapContainer
          center={markerPosition}
          zoom={15}
          scrollWheelZoom={false}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            icon={L.icon({
              iconUrl: tbgkLogo,
              iconRetinaUrl: tbgkLogo,
              iconSize: [30, 30],
              className: 'icon',
            })}
            position={markerPosition}
          />
        </MapContainer>
      )}
    </Container>
  );
}

Map.propTypes = {
  data: PropTypes.object,
};
