import styled from 'styled-components';
import { screenSize } from '../../styles/variables';

export const Container = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 1rem;

  @media (min-width: ${screenSize.smMin}) {
    height: 300px;
  }

  @media (min-width: ${screenSize.mdMin}) {
    height: 400px;
  }

  @media (min-width: ${screenSize.lgMin}) {
    height: 500px;
  }
`;

export const Logo = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: middle;
`;
