import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  screenSize,
} from '../../styles/variables';

export const Table = styled.table`
  width: 100%;
`;

export const Th = styled.th`
  font-weight: ${fontWeight.default};
  text-align: left;
`;

export const DateTd = styled.td`
  padding-right: 0.5rem;

  @media (min-width: ${screenSize.smMin}) {
    white-space: nowrap;
  }
`;

export const DateString = styled.span`
  white-space: nowrap;
`;

export const LinkTd = styled.td`
  width: 100%;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  font-size: ${fontSize.small};
`;

export const Button = styled.button`
  cursor: ${(props) => (props.isdisabled ? 'not-allowed' : 'pointer')};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: ${fontWeight.light};
  font-size: ${fontSize.small};
  background-color: transparent;
  border: 0.05rem solid
    ${(props) =>
      props.isdisabled
        ? transparentize(0.6, colors.blueDark)
        : colors.blueDark};
  color: ${(props) =>
    props.isdisabled ? transparentize(0.6, colors.blueDark) : colors.blueDark};
  padding: 0.1rem 0.25rem;

  &:hover {
    background-color: ${(props) =>
      props.isdisabled ? 'transparent' : transparentize(0.8, colors.blueDark)};
  }
`;
