import styled from 'styled-components';
import { fontWeight, screenSize } from '../../styles/variables';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 1rem;

  @media (min-width: ${screenSize.mdMin}) {
    width: 50%;
    margin-top: 0;

    &:first-child {
      margin-right: 1rem;
    }
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const Key = styled.th`
  text-align: left;
  font-weight: ${fontWeight.default};
`;

export const Value = styled.td`
  width: auto;
  white-space: nowrap;
`;
