import styled from 'styled-components';
import Img from 'gatsby-image';
import { fontSize, fontWeight, screenSize } from '../../styles/variables';

export const Container = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 0;
  overflow: hidden;

  @media (min-width: ${screenSize.mdMin}) {
    min-height: 100vh;
  }
`;

export const BackgroundImage = styled(Img)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  filter: blur(2px);
  margin: -2px;
`;

export const Inner = styled.div`
  z-index: 1;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
`;

export const Logo = styled.img`
  max-width: 10rem;
  max-height: 10rem;
  margin-bottom: 20px;
`;

export const Intro = styled.p`
  padding: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.light};
  margin: 0;
  white-space: pre-line;
`;
