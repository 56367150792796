import PropTypes from 'prop-types';
import React from 'react';
import { Markdown, Subtitle } from '../../styles/global';
import KeyValueTable from '../KeyValueTable';
import { ExceptionsToOpeningHours, Wrapper } from './styles';

export default function OpeningHours({
  data: {
    keyValueTable,
    keyValueTableTitle,
    exceptionsToOpeningHoursTitle,
    exceptionsToOpeningHours,
  },
}) {
  if (!keyValueTable) {
    return null;
  }

  return (
    <Wrapper>
      <KeyValueTable data={keyValueTable} title={keyValueTableTitle} />
      <ExceptionsToOpeningHours>
        <Subtitle>{exceptionsToOpeningHoursTitle}</Subtitle>
        <Markdown source={exceptionsToOpeningHours} />
      </ExceptionsToOpeningHours>
    </Wrapper>
  );
}

OpeningHours.propTypes = {
  data: PropTypes.object.isRequired,
};
