import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DefaultExternalLink } from '../../styles/global';
import {
  Button,
  DateString,
  DateTd,
  LinkTd,
  PaginationWrapper,
  Table,
  Th,
} from './styles';

const RESULTS_PER_PAGE = 10;

export default function Results({ results }) {
  const [currentResults, setCurrentResults] = useState(
    results.slice(0, RESULTS_PER_PAGE)
  );
  const [page, setPage] = useState(1);
  const amountOfPages = Math.ceil(results.length / RESULTS_PER_PAGE);

  useEffect(() => {
    setCurrentResults(
      results.slice((page - 1) * RESULTS_PER_PAGE, page * RESULTS_PER_PAGE)
    );
  }, [results, page]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <Th>Datum</Th>
            <Th>Tävling</Th>
          </tr>
        </thead>
        <tbody>
          {currentResults.map(({ id, title, startDate, endDate, url }) => {
            const formattedStartDate = formatISO(new Date(startDate), {
              representation: 'date',
            });
            const formattedEndDate = endDate
              ? formatISO(new Date(endDate), { representation: 'date' })
              : null;

            return (
              <tr key={id}>
                <DateTd>
                  <DateString>{formattedStartDate}</DateString>
                  {formattedEndDate ? (
                    <>
                      &nbsp;- <DateString>{formattedEndDate}</DateString>
                    </>
                  ) : null}
                </DateTd>
                <LinkTd>
                  <DefaultExternalLink
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {title}
                  </DefaultExternalLink>
                </LinkTd>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {results.length > RESULTS_PER_PAGE && (
        <PaginationWrapper>
          <Button
            aria-disabled={page <= 1}
            isdisabled={page <= 1 || null}
            onClick={() => page > 1 && setPage(page - 1)}
          >
            Nyare resultat
          </Button>
          <span>
            sida {page} of {amountOfPages}
          </span>
          <Button
            aria-disabled={page === amountOfPages}
            isdisabled={page === amountOfPages || null}
            onClick={() => page !== amountOfPages && setPage(page + 1)}
          >
            Äldre resultat
          </Button>
        </PaginationWrapper>
      )}
    </>
  );
}

Results.propTypes = {
  results: PropTypes.array.isRequired,
};
